export default {

    showDisclaimer: function (link, type) {
        if (this.$store.state.leadTimeAccepted == false) {

            this.$swal({
                html:
                    '<h2 style="color: #4d5967; margin-bottom: 20px;">Spacesaver Lead Times</h2><hr/>' +
                    '<div style="overflow:scroll; max-height: 300px; text-align: left;"><small>' +
                    '<h5>Lead times are approximate and subject to change, based on order volume and the exact product mix on your order. Please use them as a guide for planning purposes only.</h5><br/>' +
                    '<h5>IMPORTANT: Orders with special product will have additional lead time to allow for custom design and part setup in our system.</h5>' +
                    '</small></div>',
                showCloseButton: true,
                showCancelButton: false,
                confirmButtonText: `I Agree`,
                denyButtonText: `No`,
                customClass: {
                    cancelButton: 'order-1 right-gap',
                    confirmButton: 'order-2',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    //this.eulaAccepted = true;
                    this.$store.commit("ACCEPT_LEADTIMES");
                } else {
                    //this.$noty.error("Media usage agreement must be accepted for full functionality.");
                    return '';
                }
            });

        }
    },
}
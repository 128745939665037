
<template>
    <section>
    <section
      v-if="loading"
      class="py-5 bg9 bs-shadow mobile-top"
      style="min-height: 221px"
    >
      <div class="container">
        <h1 class="text-white mb0 lh1">{{loadingText}}</h1>
        <br />
        <div class="hollow-dots-spinner">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
      </div>
    </section>

    <section v-else class="py-5 bg9 bs-shadow mobile-top">
      <div class="container">
        <h1 class="text-white mb0 lh1">{{title}}</h1>
        <hr class="header-divide" />
        <div class="search-btns">
          <div class="left-side" style="margin-right: 20px">
            <div class="dates" id="dates">
              <h5 class="text-white mb0 lh1">
                {{desc}}
              </h5>
            </div>
          </div>
          <router-link
            v-if="$store.getters.isSpacesaverEmployee"
            :to="{ path: linkTarget }"
            class="search"
            style="min-width: 170px; padding-left: 10px"
          >
            {{linkText}}
            <i class="material-icons">keyboard_arrow_right</i>
          </router-link>
        </div>
      </div>
    </section>
    </section>
</template>


<script>
// used in lead times
  export default {
        name: 'Banner1',
        props: ['title', 'desc', 'linkText', 'linkTarget', 'loading', 'loadingText'],
  }
</script>


<style scoped>
.header-divide {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}

.search-btns {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
.search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  background: #4d5967;
  color: #fff;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
  max-height: 40px;
}
.search {
  margin-left: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  /* background: #519bb2; */
  background: #4d5967;
  color: #fff;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
  max-height: 40px;
}
.search {
  margin-left: 5px;
}
.search span {
  font-size: 20px;
  width: 20px;
  height: 20px;
  color: #fff;
}
.search:hover {
  background: #586575;
  text-decoration: none;
}
.search:active {
  background: #586575;
  text-decoration: none;
}
.search:disabled {
  opacity: 0.6;
  cursor: unset !important;
}
.search:disabled:hover {
  background: #4d5967;
}
.search:disabled:active {
  background: #4d5967;
}
</style>